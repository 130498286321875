@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'RobotoMono-Italic';
  src: url('./Assets/Font/roboto-mono-v22-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
@font-face {
  font-family: 'Roboto Mono';
  src: url('./Assets/Font/roboto-mono-v22-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


body {
  padding: 0;
  margin: 0;
  font-family:  'RobotoMono-Italic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

body::-webkit-scrollbar{
  background: transparent;
}
