

.desktop-container{
    background-color: #1E1E1E;
    width: 100%;
    height: 300vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.Parallax-img{
    max-width: 98vw;
    width: 98vw;
    margin: 0  1vw;
    height: calc(100% - 1rem);
}
.Parallax-about{
    max-width: 80vw;
    width: 80vw;
    height: calc(100% - 1rem);
    margin: 0vh 10vw;
}




.mobile-container{
    background-color: #1E1E1E;
    width: 100%;
    height: 500vh;
    overflow: hidden;
    transform-origin: 50% 0%;
    margin: 0;
    padding: 0;
}

.mobile-img{
    max-width: 96vw;
    width: 96vw;
    margin: 0  2vw;
    height: calc(100% - 1rem);
}

.mobile-about{
    max-width: 96vw;
    width: 96vw;
    height: calc(100% - 1rem);
    margin: 0vh 2vw;
}

.Cursor{
    position: fixed;
    bottom: 2%;
    left: 48%;
}