body{
    background-color: #1E1E1E;
}
.menu-container{
    height: 100vh;
}
.projet{
    display: flex;
    align-items: center;
    height: 50vh;
}
.list-project{
    width: 50vw;
}
.list-project-ul{
    width: 25vw;
}
.list-project li{
    padding: 1em 1em;
    background-color: #D3D3D3;
    width: auto;
    border-radius: 5em;
    font-size: 1.5vw;
    margin: 0.5em 0;
    cursor: pointer;
}


.list-project  .selected{
    background-color: black;
    color: white;
}
.img-menu{
    width: 45vw;
}

@media (max-width: 800px) and (min-width: 300px){
    .projet{
        flex-direction: column;
    }
    .list-project{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        height: 15vh;
        margin-top: 20vh;
    }
    .list-project li{
        padding: 0.75em 1em;
        background-color: #D3D3D3;
        width: auto;
        border-radius: 5em;
        font-size: 1.1em;
        margin: 0.15em 0;
        cursor: pointer;
    }
    
    
    .list-project  .selected{
        background-color: black;
        color: white;
    }
    .img-menu{
        width: 95vw;
    }
    .img-text{
        text-align: center;
        color: white;
        margin-top: 2vh;
        font-size: 10px;
    }

}