.navbar-container{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1E1E1E;
    z-index: 3;
}

.ul-Link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
    z-index: 3;
}

.ul-Link li{
    margin: 5px;
    padding: 0.25em 1em;
    background-color: #D3D3D3;
    border-radius: 5em;
    font-size: 4vh;
}



.div-btn-nav{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    top: 0em;
}

.navbar-burger{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 2em;
    top: 2vh;
    cursor: pointer;
    border: 0;
    height: 1.2rem;
    z-index: 10;
}

.burger-bar{
    width: 30px;
    height: 0.5px;
    position: relative;
    transition: all 0.3s ease-in-out;
    z-index: 4;
    transform-origin: 1px;
}
.img-nav{
    width: 10em;
    margin-top: 3em;
    z-index: 4;
    cursor: pointer;
}

.black, .burger-bar.black{
    background-color: black;
}
.white, .burger-bar.white{
    background-color: #D3D3D3;
}

@media (max-width: 800px) and (min-width: 300px){
    .img-nav{
        width: 30vw;
        margin-top: 6vh;
    }

}